<template>
    <div>
        <hero-slider :data="data" :hero_size="'large'" :is_slider="true" :is_first_heading_tag="is_first_heading_tag"/>
    </div>
</template>
<script>
    import HeroSlider from '../Components/HeroSlider.vue';

    export default {
        props: {
            data : Object,
            is_first_heading_tag : Boolean
        },
        components: {
            'hero-slider' : HeroSlider
        }
    }
</script>