<template>
    <div class="o-flex--grow">
        <template v-if="is_preview">
            <preview-notification entity="page"></preview-notification>
        </template>
        <component v-for="section in page.sections"
            :key="section.id"
            :is="`section-${ section.sectionType.slug }`"
            :data="section"
            :is_first_section="checkSectionOrder(section.id)"
            :is_first_heading_tag="checkSectionOrderHeading(section.id)"
        ></component>
    </div>
</template>
<script>
    import PreviewNotification from '../Components/UI/PreviewNotification.vue';

    export default {
        props: {
            page: Object,
            is_preview: Boolean
        },
        components: {
            'preview-notification' : PreviewNotification
        },
        methods: {
            /**
             * Checks if the section is first in order and if it's not the hero-slider
             *
             * @author Lennart van Welzen <lennart@click.nl>
             */
            checkSectionOrder(id) {
                let is_first_section;
                let first_section = this.page.sections[0];
                if(first_section.sectionType.slug != 'hero-slider' && first_section.id === id) {
                    is_first_section = true;
                } else {
                    is_first_section = false;
                }
                return is_first_section;
            },
            /**
             * Checks if the section is first in order
             *
             * @author Lennart van Welzen <lennart@click.nl>
             */
             checkSectionOrderHeading(id) {
                let is_first_heading_tag;
                let first_section = this.page.sections[0];
                if(first_section.id === id) {
                    is_first_heading_tag = true;
                } else {
                    is_first_heading_tag = false;
                }
                return is_first_heading_tag;
            }
        }
    }
</script>
