<template>
    <div class="o-flex--grow u-margin-vertical-large u-relative">
        <div class="o-row o-flex--center u-padding-top-header">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <success v-if="success_message" v-model="success_message"></success>
                <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.my_account') }}</h1>
                <div v-if="!is_verified">
                    {{ translate('js.frontend.account.not_verified_info') }}
                    <div class="u-margin-top-small">
                        <inertia-link :href="`/${$page.props.locale}/account/logout`" :class="'c-button c-button--hollow c-button--secondary'">
                            {{ translate('js.frontend.account.logout') }}
                        </inertia-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="o-row o-flex--center" v-if="is_verified">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <h2 class="c-heading--small u-no-margin-top">{{ translate('js.frontend.account.account_details') }}</h2>
                <div v-if="is_loading_user == true" class="u-margin-bottom-small">
                    <div class="c-block-loader c-block-loader--text"></div>
                    <div class="c-block-loader c-block-loader--text"></div>
                </div>
                <ul v-if="user && !is_loading_user" class="o-list-unstyled u-margin-bottom-small">
                    <li v-if="getUserFullName()" class="o-flex o-flex--column u-margin-bottom-xsmall">
                        <span class="u-font-weight-bold">{{ translate('js.frontend.account.full_name') }}</span>
                        <span>{{ getUserFullName() }}</span>
                    </li>
                    <li v-if="user.email" class="o-flex o-flex--column u-margin-bottom-xsmall">
                        <span class="u-font-weight-bold">{{ translate('js.frontend.account.email') }}</span>
                        <span>{{ user.email }}</span>
                    </li>
                    <li v-if="user.phone" class="o-flex o-flex--column">
                        <span class="u-font-weight-bold">{{ translate('js.frontend.account.phone') }}</span>
                        <span>{{ user.phone }}</span>
                    </li>
                </ul>
                <ul class="o-list-unstyled u-margin-top-small">
                    <li class="u-margin-bottom-xsmall">
                        <inertia-link :href="`/${$page.props.locale}/account/edit`" class="c-button c-button--hollow c-button--secondary">
                            {{ translate('js.frontend.account.edit_details') }}
                        </inertia-link>
                    </li>
                    <li class="u-margin-bottom-xsmall">
                        <inertia-link :href="`/${$page.props.locale}/account/change-password`" class="c-button c-button--hollow c-button--secondary">
                            {{ translate('js.frontend.account.change_password') }}
                        </inertia-link>
                    </li>
                    <li>
                        <inertia-link :href="`/${$page.props.locale}/account/logout`" class="c-button c-button--hollow c-button--secondary">
                            {{ translate('js.frontend.account.logout') }}
                        </inertia-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="o-row o-flex--center u-margin-top-medium" v-if="is_verified">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <h1 class="c-heading--small u-no-margin-top">{{ translate('js.frontend.account.order_history') }}</h1>
                <div v-if="is_loading_orders" class="c-panel__select-wrapper c-panel__select-wrapper--loading">
                    <div class="c-block-loader c-block-loader--large c-block-loader--title"></div>
                    <div class="c-block-loader c-block-loader u-no-margin-bottom"></div>
                </div>
                <template v-if="!is_loading_orders && tickets.length > 0">
                    <div v-for="(ticket, key) in tickets" class="c-panel__select-wrapper c-panel__select-wrapper--loaded" :key="key">
                        <header class="c-panel__header c-panel__header--column o-flex o-flex--justify o-flex--middle">
                            <div class="o-flex o-flex--column">
                                <h2 class="c-heading--small u-no-margin-top u-no-margin-bottom">{{ translate('js.frontend.account.order.order_number', {order_id : ticket.id }) }}</h2>
                                <span class="u-color-dark-gray">{{ translate('js.frontend.account.order.ordered_at', { date: ticket.created_at_date, time: ticket.created_at_time }) }}</span>
                            </div>
                            <div class="o-flex o-flex--middle u-margin-top-mobile u-padding-vertical-xsmall">
                                <span class="u-font-weight-bold u-show-for-sm u-margin-right-small u-margin-left-small">{{ formatPrice(ticket.paid_price) }}</span>
                                <div>
                                    <inertia-link :href="`/${$page.props.locale}/account/ticket/${ticket.id}`" class="c-button c-button--solid">
                                        {{ translate('js.frontend.account.check_order') }}
                                    </inertia-link>
                                </div>
                            </div>
                        </header>
                    </div>
                </template>
                <div v-if="!is_loading_orders && tickets.length <= 0" class="o-row">
                    <div class="o-col o-col--md-12 o-col--lg-10">
                        <p v-html="translate('js.frontend.account.no_orders_found')"></p>
                    </div>
                    <div class="o-col o-col--md-12 o-col--lg-10 u-margin-top-xsmall o-flex">
                        <inertia-link :href="`/${$page.props.locale}`" class="c-button c-button--solid">
                            {{ translate('js.frontend.account.discover_benefits') }}
                        </inertia-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Success      from '../../Components/UI/Success';
    import { Link, usePage }  from '@inertiajs/vue3';

    export default {
        props: ['message', 'is_verified'],
        components: {
            'inertia-link'  : Link,
            'success'       : Success
        },
        data() {
            return {
                user: null,
                full_name: null,
                tickets: [],
                success_message: null,
                is_loading_orders: true,
                is_loading_user: true
            }
        },
        mounted() {
            this.success_message = this.message;
            this.getUserData();
            this.getOrders();
        },
        methods: {
            /**
             * Get the data related to current user
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            getUserData:  function() {
                this.is_loading_user = true;
                axios.get(`/${usePage().props.locale}/api/my-account/user/data`)
                    .then((response) => {
                        this.user = response.data;
                        this.is_loading_user = false;
                    })
            },

            /**
             * Get the orders related to current user
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return void
             */
            getOrders:  function() {
                this.is_loading_orders = true;
                axios.get(`/${usePage().props.locale}/api/my-account/get-orders`)
                    .then((response) => {
                        this.tickets = response.data;
                        this.is_loading_orders = false;
                    });
            },

            /**
             * Create full name string for the current user
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return string
             */
            getUserFullName: function() {
                let full_name = null;
                if(this.user.first_name){
                    full_name = this.user.first_name;
                }
                if(this.user.insertion){
                    full_name = full_name + ' ' + this.user.insertion;
                }
                if(this.user.last_name){
                    full_name = full_name + ' ' + this.user.last_name;
                }
                return full_name;
            }
        }
    }
</script>