<template>
    <svg :width="width" :height="height" :class="classes" aria-hidden="true" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.6704 9.94394L20.2492 10.3651C20.2468 10.3675 20.2443 10.37 20.2419 10.3724L10.7794 19.8349L9.93086 18.9864L18.3727 10.5445H1.37963C1.04826 10.5445 0.779633 10.2759 0.779633 9.9445C0.779633 9.61312 1.04826 9.34449 1.37963 9.34449H18.3736L9.9309 0.903275L10.7793 0.0546722L20.6704 9.94394Z"></path>
    </svg>
</template>

<script>
export default {
    name: 'ArrowRight',
    props: {
        width: {
            type: Number,
            default: 20
        },
        height: {
            type: Number,
            default: 20
        },
        classes: String
    }
}
</script>