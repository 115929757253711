<template>
    <div v-if="!sign_up_success" class="o-flex--grow u-margin-vertical-large">
        <div class="o-row o-flex--center u-padding-top-header">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <errors :errors="errors"></errors>
                <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.sign_up') }}</h1>
            </div>
        </div>
        <div class="o-row o-flex--center">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <p class="u-no-margin-top">
                    <small>{{ translate('js.frontend.account.fields_required') }}</small>
                </p>
                <p v-if="global_error" class="u-margin-bottom-small u-color-alert u-no-margin-top u-font-weight-bold">
                    {{ global_error }}
                </p>
                <form class="c-form" v-on:submit.prevent="submitForm">
                    <div class="c-form--grow">
                        <div class="c-form__group">
                            <label for="first-name">{{ translate('js.frontend.account.first_name') }} {{ translate('js.frontend.account.is_required') }}</label>
                            <input class="c-form__input" id="first-name" type="text" v-model="form.first_name" :maxLength="128">
                            <p v-if="hasError('first_name')" class="c-form__message c-form__message--error">
                                {{ getError('first_name') }}
                            </p>
                        </div>
                        <div class="c-form__group--infix">
                            <label for="infix">{{ translate('js.frontend.account.insertion') }}</label>
                            <input class="c-form__input" id="infix" type="text" v-model="form.insertion" :maxLength="128">
                            <p v-if="hasError('insertion')" class="c-form__message c-form__message--error">
                                {{ getError('insertion') }}
                            </p>
                        </div>
                        <div class="c-form__group">
                            <label for="last-name">{{ translate('js.frontend.account.last_name') }} {{ translate('js.frontend.account.is_required') }}</label>
                            <input class="c-form__input" id="last-name" type="text" v-model="form.last_name" :maxLength="128">
                            <p v-if="hasError('last_name')" class="c-form__message c-form__message--error">
                                {{ getError('last_name') }}
                            </p>
                        </div>
                    </div>
                    <div class="c-form--grow">
                        <div class="c-form__group">
                            <label for="email">{{ translate('js.frontend.account.email') }} {{ translate('js.frontend.account.is_required') }}</label>
                            <input class="c-form__input" id="email" type="email" v-model="form.email" :placeholder="translate('js.db.placeholder.email')" :maxLength="256">
                            <p v-if="hasError('email')" class="c-form__message c-form__message--error">
                                {{ getError('email') }}
                            </p>
                        </div>
                        <div class="c-form__group">
                                <label for="phone">{{ translate('js.frontend.account.phone') }}</label>
                                <input class="c-form__input" id="phone" type="tel" v-model="form.phone" :maxLength="32">
                                <p v-if="hasError('phone')" class="c-form__message c-form__message--error">
                                    {{ getError('phone') }}
                                </p>
                        </div>
                    </div>
                    <div class="c-form--grow">
                        <div class="c-form__group">
                            <label for="password">{{ translate('js.frontend.account.password') }} {{ translate('js.frontend.account.is_required') }}</label>
                            <input class="c-form__input" id="password" type="password" v-model="form.password" :placeholder="translate('js.db.placeholder.password')">
                            <p v-if="hasError('password')" class="c-form__message c-form__message--error">
                                {{ getError('password') }}
                            </p>
                        </div>
                        <div class="c-form__group">
                            <label for="password">{{ translate('js.frontend.account.repeat_password') }} {{ translate('js.frontend.account.is_required') }}</label>
                            <input class="c-form__input" id="password" type="password" v-model="form.password_confirmation" :placeholder="translate('js.db.placeholder.password')">
                            <p v-if="hasError('password_confirmation')" class="c-form__message c-form__message--error">
                                {{ getError('password_confirmation') }}
                            </p>
                        </div>
                    </div>
                    <div class="o-flex o-flex--justify">
                        <inertia-link :href="`/${$page.props.locale}/account/login`" class="c-button c-button--hollow c-button--secondary">
                            {{ translate('js.frontend.global.go_back') }}
                        </inertia-link>

                        <button :disabled="disable_submit" type="submit" class="c-button c-button--solid">{{ translate('js.frontend.account.create_account') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="o-flex--grow u-margin-vertical-large" v-if="sign_up_success">
        <div class="o-row o-flex--center u-padding-top-header">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <h2 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.account.confirm_request') }}</h2>
            </div>
        </div>
        <div class="o-row o-flex--center">
            <div class="o-col o-col--md-12 o-col--lg-10">
                {{ translate('js.frontend.account.sign_up_success', { email: form.email }) }}
            </div>
        </div>
    </div>
</template>

<script>
    import { Link, usePage } from '@inertiajs/vue3';
    import Errors from '../../Components/UI/Errors';

    export default {
        props: {
            email: String
        },
        data() {
            return {
                form: {},
                errors: null,
                sign_up_success: false,
                global_error: null,
                disable_submit: false
            }
        },
        components: {
            'inertia-link' : Link,
            'errors' : Errors
        },
        mounted()
        {
            this.form.email = this.email;
        },
        methods: {
            /**
             * Submit registration form
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitForm()
            {
                this.errors = null;
                this.disable_submit = true;
                axios.post(`/${usePage().props.locale}/api/register`, this.form)
                    .then(this.submitSuccess)
                    .catch(this.catchErrors);
            },

            /**
             * Submit success
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return void
             */
            submitSuccess(response)
            {
                this.sign_up_success = true;
            },

            /**
             * Catch errors (from submitForm)
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  error
             * @return void
             */
            catchErrors(errors)
            {
                this.disable_submit = false;

                if( errors.response.status == 422){
                    this.errors = errors.response;
                } else if( errors.response.status == 403 ) {
                    window.location.href = errors.response.data.route;
                }else{
                    this.errors = [];
                    this.errors['general'] = Lang.get('js.frontend.global.something_went_wrong');
                }
            },

            /**
             * Get the error for the field
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return string
             */
            getError(field)
            {
                if(this.errors && this.errors.data){
                    return this.errors.data.errors[field][0];
                }
            },

            /**
             * Check if the form field has an error
             *
             * @author Bas Lokerman <bas@click.nl>
             * @param  {string} error_field
             * @return boolean
             */
            hasError(field) {
                return this.errors && this.errors.data && this.errors.data.errors && this.errors.data.errors[field];
            },


        }
    }
</script>