<template>
    <div class="o-row o-flex--center">
        <div class="o-col o-col--md-12 o-col--lg-5 u-margin-bottom-medium">
            <template v-if="!is_free_order">
                <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.checkout.confirm.header.'+this.payment_status) }}</h1>
            </template>
            <template v-else>
                <h1 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.checkout.confirm.header.free') }}</h1>
            </template>
            <div class="u-margin-vertical-medium">
                <p v-if="['paid', 'pending'].includes(payment_status) || is_free_order">
                    <template v-if="!is_non_account_order">
                        <strong>{{ translate('js.frontend.checkout.confirm.customer_id') }}:</strong> {{ customer_id }}<br />
                    </template>
                    <strong>{{ translate('js.frontend.checkout.confirm.order_id') }}:</strong> {{ order_id }}
                </p>
                <p v-html="confirmDescription"></p>
            </div>
            <inertia-link :href="`/${$page.props.locale}`" class="c-button c-button--solid">
                {{ translate('js.frontend.checkout.more_benefits') }}
            </inertia-link>
        </div>
        <div class="o-col o-col--sm-12 o-col--md-6 o-col--lg-5 c-widget">
            <a :href="widget9292Url" data-9292="widget" data-9292-text="" data-9292-ref="" data-9292-width="100%" data-9292-height="575" :data-9292-api-key="data_9292_api_key" :data-9292-widget-key="data_9292_widget_key" data-targetblank="on">{{ translate('js.frontend.checkout.9292_widget_title') }}</a>
        </div>
    </div>
</template>

<script>
    import { Link, usePage }  from '@inertiajs/vue3';

    export default {
        props : {
            is_create_account: Boolean,
            data_9292_widget_key: String,
            data_9292_api_key: String,
            error_message: String,
            payment_status: String,
            is_free_order: Boolean,
            order_id: Number,
            customer_id: Number,
            is_non_account_order: Boolean
        },
        components: {
            'inertia-link' : Link
        },
        computed: {
            /**
             * Sets correct widget url for each language
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return {string} widget_url
             */
            widget9292Url() {
                if (usePage().props.locale == 'nl') {
                    return 'https://9292.nl/'
                } else {
                    return 'https://9292.nl/en';
                }
            },
            /**
             * Sets the confirm description based on login type
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return {string}
             */
            confirmDescription(){
                if( this.is_create_account === true ){
                    return this.is_free_order ? Lang.get('js.frontend.checkout.confirm.description_register.free') : Lang.get('js.frontend.checkout.confirm.description_register.'+this.payment_status);
                }else{
                    return this.is_free_order ? Lang.get('js.frontend.checkout.confirm.description.free') : Lang.get('js.frontend.checkout.confirm.description.'+this.payment_status);
                }
            }
        },
        mounted() {
            //Set 9292 widget
            let widget9292 = document.createElement('script');
            widget9292.setAttribute('src', 'https://9292.nl/static/js/widget.js');
            document.head.appendChild(widget9292);
        },
    }
</script>
