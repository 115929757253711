<template>
    <div class="o-flex--grow u-margin-vertical-large">
        <div class="o-row o-flex--center u-padding-top-header">
            <div class="o-col o-col--md-12 o-col--lg-10 u-margin-bottom-medium">
                <div class="u-margin-bottom-small">
                    <inertia-link :href="`/${$page.props.locale}/account/dashboard`">
                         {{ translate('js.frontend.account.my_account') }}
                    </inertia-link>
                </div>
                <h1 class="c-heading--medium u-no-margin-top u-no-margin-bottom">{{ translate('js.frontend.account.order.order_number', {order_id : order.data.id }) }}</h1>
                <span class="u-color-dark-gray">{{ translate('js.frontend.account.order.ordered_at', { date: order.data.created_at_date, time: order.data.created_at_time }) }}</span>
            </div>
        </div>
        <div class="o-row o-flex--center u-margin-bottom-medium">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <h2 class="c-heading--small u-no-margin-top">{{ translate('js.frontend.order.order_details') }}</h2>
                <ul class="o-list-unstyled u-margin-bottom-small">
                    <li v-if="order.data.full_name" class="o-flex o-flex--column u-margin-bottom-xsmall">
                        <span class="u-font-weight-bold">{{ translate('js.frontend.account.full_name') }}</span>
                        {{ order.data.full_name }}
                    </li>
                    <li v-if="order.data.email" class="o-flex o-flex--column u-margin-bottom-xsmall">
                        <span class="u-font-weight-bold">{{ translate('js.frontend.account.email') }}</span>
                        {{ order.data.email }}
                    </li>
                    <li v-if="order.data.phone" class="o-flex o-flex--column">
                        <span class="u-font-weight-bold">{{ translate('js.frontend.account.phone') }}</span>
                        {{ order.data.phone }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="o-row o-flex--center">
            <div class="o-col o-col--md-12 o-col--lg-10">
                <h2 class="c-heading--small u-no-margin-top">{{ translate('js.frontend.account.order.orders') }}</h2>
                <shopping-cart-item v-for="order_item in order.data.order_items" :key="order_item.id" :product="order_item" :is_edit_disabled="true" :is_loading="false" :order_item_id="order_item.id"/>
                <div class="c-panel u-no-padding-top o-flex o-flex--justify">
                    <span class="u-font-weight-bold">{{ translate('js.frontend.checkout.shopping_cart.total') }}</span>
                    <span class="u-font-weight-bold">{{ formatPrice(order.data.paid_price) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Link } from '@inertiajs/vue3';
    import ShoppingCartItem from '../../Components/ShoppingCartItem';

    export default {
        props : {
            order: Object
        },
        components: {
            'inertia-link' : Link,
            'shopping-cart-item' : ShoppingCartItem
        }
    }
</script>
