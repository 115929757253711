<template>
    <div class="c-hero u-xl-padding-top-small">
        <section class="c-hero--featured u-full-width u-max-w-xl u-bg-gradient u-xl-rounded-md">
            <img :src="`${$page.props.cdn_url}/images/frontend/hero-top-swirl.svg`" class="c-hero__swirl--small">
            <img :src="`${$page.props.cdn_url}/images/frontend/hero-bottom-swirl.svg`" class="c-hero__swirl--large">
            <div class="o-row o-flex--middle">
                <div class="o-col o-col--sm-12 o-col--lg-5 u-color-white u-lg-no-padding-right">
                    <div class="c-hero__content">
                        <h1 class="c-hero__title u-no-margin-top">{{ data.title }}</h1>
                        <p>{{ data.subtitle }}</p>
                        <div v-if="data.translated_first_button && data.translated_first_button.button_text && data.translated_first_button.button_url" :class="'o-flex o-flex--' + this.text_alignment">
                            <template v-if="data.translated_first_button.is_button_target_blank === '1'">
                                <a :href="data.translated_first_button.button_url" target="_blank" rel="noopener" class="c-button c-button--hollow c-button--white u-margin-top-xsmall">
                                    {{ data.translated_first_button.button_text }}
                                </a>
                            </template>
                            <template v-else>
                                <inertia-link :href="data.translated_first_button.button_url" :class="'c-button c-button--hollow c-button--white u-margin-top-xsmall'">
                                    {{ data.translated_first_button.button_text }}
                                </inertia-link>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="o-col o-col--sm-12 o-col--lg-7 u-lg-no-padding-left">
                    <responsive-image
                        v-if="data.image_object"
                        :is_background_image="false"
                        :class_name="'c-hero__image u-full-width u-rounded-md'"
                        :small="data.image_object.image_small"
                        :medium="data.image_object.image_medium"
                        :large="data.image_object.image_large"
                        :alt_text="data.image_object.alt_text ? data.image_object.alt_text : data.title">
                    </responsive-image>
                </div>
            </div>
        </section>
        <div class="c-hero__cards o-row">
            <template v-if="is_loading">
                <div v-for="card in this.loading_cards_amount" class="o-col o-col--sm-12 o-col--md-6 o-col--lg-4 u-margin-bottom-small" :key="card">
                    <card :is_loading="true"/>
                </div>
            </template>
            <template v-else>
                <div v-for="card in this.cards" class="o-col o-col--sm-12 o-col--md-6 o-col--lg-4 u-margin-bottom-small" :key="card.id">
                    <card :data="card"/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import ResponsiveImage from './ResponsiveImage';
    import Card from './Card.vue';
    import { Link } from '@inertiajs/vue3';

    export default {
        props: {
            data : Object,
            cards : Object,
            image_object: Object,
            is_first_heading_tag : Boolean,
            is_loading : Boolean
        },
        components: {
            'responsive-image' : ResponsiveImage,
            'card' : Card,
            'inertia-link' : Link
        },
        data() {
            return {
                loading_cards_amount: 3,
            }
        },
        methods: {

        }
    }
</script>
