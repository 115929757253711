<template>
    <errors :errors="errors"></errors>
    <div v-if="is_loading" class="c-panel__select-wrapper c-panel__select-wrapper--loading">
        <header class="c-block-loader c-block-loader--large c-block-loader--title"></header>
        <div class="c-block-loader c-block-loader"></div>
        <div class="c-block-loader c-block-loader u-no-margin-bottom"></div>
    </div>
    <div v-else class="c-panel c-panel__select-wrapper c-panel__select-wrapper--loaded">
        <header class="c-panel__header o-flex o-flex--justify">
            <div class="o-flex o-flex--column">
                <h2 class="c-heading--small u-no-margin-top u-no-margin-bottom">{{ product.title }}</h2>
                <span v-if="product.formatted_date_time" class="u-color-dark-gray">
                    {{ translate('js.frontend.checkout.shopping_cart.valid_at', { date_time: product.formatted_date_time }) }}
                </span>
            </div>
            <div v-if="!is_edit_disabled" @click="removeFromCart(product.id, product.order_index)" class="c-panel__delete o-flex--align-self-start o-flex o-flex--middle u-margin-left-small">
                <span class="c-panel__delete--active o-flex--align-self-center u-margin-left-xsmall far fa-bin"></span>
            </div>
        </header>
        <div class="c-panel__body">
            <div class="c-panel__item o-flex o-flex--justify" v-for="product_variant in product.product_variants" :key="product_variant.id">
                <div class="o-flex o-flex--middle">
                    <div v-if="!is_edit_disabled" @click="removeFromCart(product.id, product.order_index, product_variant.id)" class="c-panel__delete u-margin-right-xsmall">
                        <span class="c-panel__delete--active far fa-bin"></span>
                    </div>
                    <span>{{ product_variant.title }}</span>
                </div>
                <div class="o-flex o-flex--middle u-margin-left-small">
                    <v-select
                        v-if="!is_edit_disabled"
                        @option:selected="updateAmount(product.id, product_variant.id, product.order_index, product_variant.amount)"
                        v-model="product_variant.amount"
                        :options="getOrderableAmounts"
                        :clearable="false"
                        :searchable="false"
                        :key="product_variant.id">
                    </v-select>
                    <span v-if="!is_edit_disabled" class="u-margin-left-xsmall u-margin-right-xsmall">x</span>
                    <span v-if="is_edit_disabled">{{ product_variant.amount }}</span>
                    <span v-if="is_edit_disabled" class="u-margin-left-xsmall u-margin-right-xsmall">x</span>
                    <span class="c-panel__price">
                        <span class="u-margin-left-xsmall">{{ formatPrice(product_variant.price) }}</span>
                    </span>
                </div>
            </div>
        </div>
        <footer v-if="is_edit_disabled" class="c-panel__footer o-flex o-flex--column o-flex--justify">
            <div class="o-flex o-flex--justify o-flex--middle">
                <span class="u-font-weight-bold">{{ translate('js.frontend.checkout.shopping_cart.subtotal') }}</span>
                <span class="u-font-weight-bold">{{ formatPrice(totalPrice(product.product_variants)) }}</span>
            </div>
            <div v-if="order_item_id && !is_overview && (product.order_item_status && product.order_item_status === 'success')" class="o-flex u-margin-top-small">
                <button :disabled="is_download_disabled" class="c-button c-button--solid" :class="{ 'is-disabled' : is_download_disabled }" @click="downloadOrderPDF()" type="button">
                    {{ translate('js.frontend.checkout.shopping_cart.download_pdf') }}
                </button>
                <!-- Loader end load message -->
                <div v-if="is_download_disabled" class="o-flex o-flex--middle u-margin-left-xsmall">
                    <div class="c-loader is-xsmall u-margin-right-xsmall"><div class="c-loader__inner"></div></div>
                    <small>{{ translate('js.frontend.account.download_in_progress') }}</small>
                </div>
            </div>
        </footer>
        <footer v-else class="c-panel__footer o-flex o-flex--justify">
            <span class="u-font-weight-bold">{{ translate('js.frontend.checkout.shopping_cart.subtotal') }}</span>
            <span class="u-font-weight-bold">{{ formatPrice(totalPrice(product.product_variants)) }}</span>
        </footer>
    </div>
</template>

<script>
    import vSelect              from 'vue-select';
    import { usePage }          from '@inertiajs/vue3';
    import Errors               from './UI/Errors.vue';

    export default {
        props: {
            is_edit_disabled : {
                type: Boolean,
                default: null
            },
            is_overview : {
                type: Boolean,
                default: null
            },
            product : {
                type: Object,
                default: () => {}
            },
            order_item_id : {
                type: Number,
                default: null
            },
            is_loading : {
                type: Boolean,
                default: true
            }
        },
        components: {
            'v-select' : vSelect,
            'errors'   : Errors,
        },
        data() {
            return {
                is_max_count: false,
                max_ticket_amount: usePage().props.max_ticket_amount,
                is_download_disabled : false,
                errors: null,
            }
        },
        emits: ['amountUpdated', 'updateProducts'],
        mounted() {
            if(this.product){
                this.getCurrentProductCount();
            }
        },
        computed : {
            /**
             * Returns an array counting up from 1 to 10
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return array
             */
            getOrderableAmounts: function() {
                let count = [];
                for(let i= 1; i < 11; i++) {
                    count.push(i);
                }
                return count;
            }
        },
        methods: {
            /**
             * Get current product count
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return  {void}
             */
            getCurrentProductCount: function() {
                let total = 0;
                this.product.product_variants.filter((value) => {
                    total += value.amount;
                });

                //If total is more than the max possible amount show error
                if(total > this.max_ticket_amount){
                    this.errors = Lang.get('js.frontend.checkout.ticket_limit_exceeded', {ticket_limit:this.max_ticket_amount});
                }

                this.$emit('amountUpdated', this.is_max_count);
            },
            /**
             * Calculates the total price of the ordered tickets
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param product_articles
             * @return int
             */
            totalPrice: function(product_articles) {
                let total = 0;
                // loop through every product article and count total + product article * amount
                product_articles.forEach(product_article => {
                    total += (parseFloat(product_article.price) * parseInt(product_article.amount));
                });
                return total;
            },
            /**
             * Removes the product or product variant from the session (and shopping cart)
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param  product_id
             * @param  product_variant_id
             * @return void
             */
            removeFromCart: function(product_id, order_index, product_variant_id) {
                let items_to_remove = {order_index:order_index};
                items_to_remove['product_id'] = product_id;

                if(product_variant_id != undefined) {
                    items_to_remove['product_variant_id'] = product_variant_id;
                }

                axios.post(`/${usePage().props.locale}/api/checkout/remove-from-shopping-cart`, items_to_remove)
                    .then((response) => {
                        this.$emit('updateProducts', response.data)
                    }).catch((errors) => {
                        this.errors = Lang.get('js.frontend.checkout.errors.cart_error');
                    });
            },
            /**
             * Update the amount of the product variant
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param  product_id
             * @param  product_variant_id
             * @param  amount
             * @return void
             */
            updateAmount: function(product_id, product_variant_id, order_index, amount) {
                this.errors = null;
                this.getCurrentProductCount();
                let product_variant_to_update = {product_id:product_id, product_variant_id:product_variant_id, order_index:order_index, amount:amount};
                axios.post(`/${usePage().props.locale}/api/checkout/update-shopping-cart-amount`, product_variant_to_update)
                    .then((response) => {
                        this.$emit('updateProducts', response.data)
                    }).catch((errors) => {
                        this.errors = Lang.get('js.frontend.checkout.errors.cart_error');
                    });
            },
            /**
             * Download order PDF
             *
             * @author Bas Lokerman
             * @return void
             */
            downloadOrderPDF: function(){
                this.errors = null;
                this.is_download_disabled = true;

                axios({
                    url:`/${usePage().props.locale}/api/my-account/order/${this.order_item_id}/download`,
                    method: 'GET',
                    responseType: 'blob',
                })
                .then((response) => {
                    // create file link in browser's memory
                    var href = URL.createObjectURL(new Blob( [response.data], {type: 'application/pdf;base64'}));

                    // create "a" HTML element with href to file & click
                    var link = document.createElement('a');
                    link.href = href;

                    //Get content-disposition data from the headers
                    var content_disposition = response.headers['content-disposition'];

                    //Strip filename data from the content disposition data
                    var file_name = 'voucher.pdf';
                    if (content_disposition) {
                        var file_name_match = content_disposition.match(/filename="(.+)"/);
                        if (file_name_match.length === 2){
                            file_name = file_name_match[1];
                        }
                    }

                    //Set file name and click the generated link
                    link.setAttribute('download', file_name);
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    this.is_download_disabled = false;
                })
                .catch((errors) => {
                    this.is_download_disabled = false;
                    this.errors = Lang.get('js.frontend.global.something_went_wrong');
                })
            },
        }
    }
</script>