<template>
    <div :class="'u-text-' + this.text_alignment">
        <h1 v-if="item.title && is_first_heading_tag" class="u-no-margin-top u-margin-bottom-small u-break-word" :class="['c-heading--' + this.font_size, 'u-color-' + this.font_color]">{{ item.title }}</h1>
        <h2 v-if="item.title && !is_first_heading_tag" class="u-no-margin-top u-margin-bottom-small u-break-word" :class="['c-heading--' + this.font_size, 'u-color-' + this.font_color]">{{ item.title }}</h2>
        <span v-if="item.subtitle">{{ item.subtitle }}</span>
        <div v-if="item.description" class="u-margin-bottom-small" :class="'u-color-' + this.font_color" v-html="item.description"></div>
        <div v-if="item.translated_first_button && item.translated_first_button.button_text && item.translated_first_button.button_url" :class="'o-flex o-flex--' + this.text_alignment">
            <template v-if="item.translated_first_button.is_button_target_blank === '1'">
                <a :href="item.translated_first_button.button_url" target="_blank" rel="noopener" class="c-button c-button--solid">
                    {{ item.translated_first_button.button_text }}
                </a>
            </template>
            <template v-else>
                <inertia-link :href="item.translated_first_button.button_url" :class="'c-button c-button--solid'">
                    {{ item.translated_first_button.button_text }}
                </inertia-link>
            </template>
        </div>
    </div>
</template>

<script>
    import { Link } from '@inertiajs/vue3';

    export default {
        components: {
            'inertia-link' : Link
        },
        props: {
            item : Object,
            font_size : String,
            font_color : String,
            text_alignment : String,
            is_hero : Boolean,
            button_type : String,
            is_first_section : Boolean,
            is_first_heading_tag : Boolean
        }
    }
</script>