<template>
    <div class="o-row o-flex--center">
        <div class="o-col o-col--md-12 o-col--lg-10">
            <errors :errors="errors"></errors>
            <h2 class="c-heading--medium u-margin-bottom-medium u-no-margin-top">{{ translate('js.frontend.checkout.user_data.header') }}</h2>
        </div>
    </div>
    <div class="o-row o-flex--center">
        <div class="o-col o-col--md-12 o-col--lg-10">
            <p class="u-no-margin-top">
                <small>{{ translate('js.frontend.account.fields_required') }}</small>
            </p>
            <div class="c-form">
                <p v-if="Object.keys(this.errors).length > 0 && this.errors['general']" class="u-margin-top-xsmall u-margin-bottom-medium u-color-alert u-font-weight-bold">{{this.errors['general'] }}</p>
                <div class="c-form--grow">
                    <div class="c-form__group">
                        <label for="first-name">{{ translate('js.frontend.account.first_name') }} {{ translate('js.frontend.account.is_required') }}</label>
                        <input class="c-form__input" id="first-name" v-model="form.first_name" :maxLength="128">
                        <p v-if="hasError('first_name')" class="c-form__message c-form__message--error">{{ getError('first_name') }}</p>
                    </div>
                    <div class="c-form__group--infix">
                        <label for="infix">{{ translate('js.frontend.account.insertion') }}</label>
                        <input class="c-form__input" id="infix" v-model="form.insertion" >
                        <p v-if="hasError('insertion')" class="c-form__message c-form__message--error" :maxLength="128">{{ getError('insertion') }}</p>
                    </div>
                    <div class="c-form__group">
                        <label for="last-name">{{ translate('js.frontend.account.last_name') }} {{ translate('js.frontend.account.is_required') }}</label>
                        <input class="c-form__input" id="last-name" v-model="form.last_name" :maxLength="128">
                        <p v-if="hasError('last_name')" class="c-form__message c-form__message--error">{{ getError('last_name') }}</p>
                    </div>
                </div>
                <div class="c-form--grow">
                    <div class="c-form__group">
                        <label for="email">{{ translate('js.frontend.account.email') }} {{ translate('js.frontend.account.is_required') }}</label>
                        <input class="c-form__input" id="email" v-model="form.email" :disabled="$page.props.is_logged_in" :maxLength="256">
                        <p v-if="hasError('email')" class="c-form__message c-form__message--error">{{ getError('email') }}</p>
                    </div>
                    <div class="c-form__group">
                        <label for="phone">{{ translate('js.frontend.account.phone') }}</label>
                        <input class="c-form__input" id="phone" type="tel" v-model="form.phone" :maxLength="32">
                        <p v-if="hasError('phone')" class="c-form__message c-form__message--error">{{ getError('phone') }}</p>
                    </div>
                </div>
                <div v-if="form.is_create_account" class="c-form--grow">
                    <div class="c-form__group">
                     <label for="password">{{ translate('js.frontend.account.password') }} {{ translate('js.frontend.account.is_required') }}</label>
                     <input class="c-form__input" id="password" type="password" v-model="form.password" :placeholder="translate('js.db.placeholder.password')">
                     <p v-if="hasError('password')" class="c-form__message c-form__message--error">
                         {{ getError('password') }}
                     </p>
                    </div>
                    <div class="c-form__group">
                     <label for="password">{{ translate('js.frontend.account.repeat_password') }} {{ translate('js.frontend.account.is_required') }}</label>
                     <input class="c-form__input" id="password" type="password" v-model="form.password_confirmation" :placeholder="translate('js.db.placeholder.password')">
                     <p v-if="hasError('password_confirmation')" class="c-form__message c-form__message--error">
                         {{ getError('password_confirmation') }}
                     </p>
                    </div>
                </div>
                <div class="o-flex o-flex--justify">
                    <inertia-link :href="previousLink" :class="'c-button c-button--hollow c-button--secondary'">
                        {{ translate('js.frontend.checkout.previous_step') }}
                    </inertia-link>

                    <button @click="saveUserData" type="button" class="c-button c-button--solid">
                        {{ translate('js.frontend.checkout.next_step') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Link, usePage } from '@inertiajs/vue3';
    import Errors from '../../Components/UI/Errors';

    export default {
        props: {
            user: Object,
            validation_errors: {
                type: Array,
                default: () => []
            }
        },
        components: {
            'inertia-link' : Link,
            'errors' : Errors
        },
        data() {
            return {
                form: {},
                errors: {}
            }
        },
        mounted : function() {
            //Set session errors
            if(this.validation_errors){
                this.errors = this.validation_errors;
            }
            this.form = this.user;
        },
        computed : {
            /**
             * Sets previous link based on user login
             *
             * @author Bas Lokerman
             * @return string
             */
            previousLink() {
                if(usePage().props.is_logged_in === true){
                    return `/${usePage().props.locale}/checkout/shopping-cart`;
                }else{
                    return `/${usePage().props.locale}/checkout/login`;
                }
            }
        },
        methods : {
            /**
             * Save the inputted user data. On succes go to the next step, otherwise show one or multiple errors
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return  void
             */
            saveUserData: function() {
                axios.post(`/${usePage().props.locale}/api/checkout/save-user-data`, this.form)
                    .then((response) => {
                        window.location.href = response.data.route;
                    })
                    .catch((errors) => {
                        if(errors.response){
                            if( errors.response.status == 422){
                                this.errors = errors.response;
                            } else if( errors.response.status == 403 ) {
                                window.location.href = errors.response.data.route;
                            }else{
                                this.errors['general'] = Lang.get('js.frontend.global.something_went_wrong');
                            }
                        }
                    });
            },
            /**
             * Check if any field has any error
             *
             * @author Joost Ligthart <joost@click.nl>
             * @return  boolean
             */
            hasError: function(field) {
                return this.errors && this.errors.data && this.errors.data.errors && this.errors.data.errors[field];
            },
            /**
             * Get the error based on the received field name
             *
             * @author Joost Ligthart <joost@click.nl>
             * @param  field
             * @return void
             */
            getError: function(field) {
                if(this.errors && this.errors.data){
                    return this.errors.data.errors[field][0];
                }
            },
        }
    }
</script>