<template>
    <div class="o-row">
        <template v-if="is_loading">
            <div v-for="card in this.loading_cards_amount" class="o-col o-col--xs-12 o-col--md-6 o-col--lg-4 u-margin-bottom-small" :key="card">
                <card :is_loading="true"/>
            </div>
        </template>
        <template v-else>
            <template v-if="cards.length > 3">
                <div class="o-col o-col--xs-12 u-relative u-z-1">
                    <div class="c-swiper u-full-height">
                        <div :class="{ 'is-hidden': is_beginning }" class="c-swiper__arrow-prev">
                            <span class="c-button__icon c-icon__arrow-left c-icon__arrow-left--black u-no-margin"></span>
                        </div>
                        <div :class="{ 'is-hidden': is_end }" class="c-swiper__arrow-next">
                            <span class="c-button__icon c-icon__arrow-right c-icon__arrow-right--black u-no-margin"></span>
                        </div>
                        <swiper-container
                            :slidesPerView="slidesPerView"
                            :spaceBetween="spaceBetween"
                            :breakpoints="breakpoints"
                            :navigation="{
                                prevEl: '.c-swiper__arrow-prev',
                                nextEl: '.c-swiper__arrow-next'
                            }"
                            scrollbar="true"
                            @swiperinit="onSwiperInit"
                            @swiperslidechange="updateNavigationState"
                        >
                            <swiper-slide v-for="(card, index) in cards" :key="index">
                                <card :data="card"/>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-for="card in this.cards" class="o-col o-col--sm-12 o-col--md-6 o-col--lg-4 u-margin-bottom-small" :key="card.id">
                    <card :data="card"/>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import Card from './Card.vue';

    export default {
        props: {
            cards : Object,
            is_loading : Boolean
        },
        data() {
            return {
                loading_cards_amount: 3,
                slidesPerView: 1,
                spaceBetween: 24,
                is_beginning: true, // Indicates if the slider is at the beginning
                is_end: false, // Indicates if the slider is at the end
                breakpoints: {
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 36,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 36,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 44,
                    }
                }
            }
        },
        components: {
            'card' : Card
        },
        methods: {
            /**
             * Updates the state of `isBeginning` and `isEnd` based on the Swiper instance.
             *
             * @param {Object} swiper - The Swiper instance
             *
             * @return {void}
             *
             * @author Rianne Oosthoek
             */
            updateSwiperState(swiper) {
                this.is_beginning = swiper.isBeginning;
                this.is_end = swiper.isEnd;
            },

            /**
            * Triggered when Swiper initializes.
            * Calls `updateSwiperState` to set the initial navigation state.
            *
            * @return {void}
            *
            * @author Rianne Oosthoek <rianne@click.nl>
            */
            onSwiperInit() {
                this.updateSwiperState({
                    isBeginning: true,
                    isEnd: false
                });
            },

            /**
            * Triggered when the slide changes.
            * Calls `updateSwiperState` to update the navigation state.
            *
            * @param {Event} event - The swiperslidechange event object
            *
            * @return {void}
            *
            * @author Rianne Oosthoek <rianne@click.nl>
            */
            updateNavigationState(event) {
                const swiper = event.detail[0]; // swiper instance
                this.updateSwiperState(swiper);
            },
        }
    }
</script>