<template>
    <div :class="setSectionStyling(data.background_color, is_first_section)" data-section-type="hero-featured-products">
        <hero-featured-products :data="data" :cards="cards" :is_loading="is_loading" :is_first_heading_tag="is_first_heading_tag"/>
    </div>
</template>
<script>
    import HeroFeaturedProducts from '../Components/HeroFeaturedProducts.vue';
    import { usePage } from '@inertiajs/vue3';

    export default {
        props: {
            data : Object,
            is_first_section: Boolean,
            is_first_heading_tag : Boolean
        },
        components: {
            'hero-featured-products' : HeroFeaturedProducts
        },
        data: () => {
            return {
                cards: [],
                is_loading: false
            }
        },
        mounted: function() {
            this.getProducts();
        },
        methods: {
            /**
             * Fetches products for the current card overview from the API.
             *
             * This method sets `is_loading` to true to indicate loading state,
             * sends a GET request to the API endpoint for fetching products,
             * and updates the `cards` property with the response data.
             * Once the request is completed, it sets `is_loading` to false
             * so the actuals cards will be visible in the section.
             *
             * @return {void} This method does not return any value.
             */
            getProducts: function() {
                this.is_loading = true;
                axios.get(`/${usePage().props.locale}/api/card-overview/${this.data.id}/get-products`)
                    .then((response) => {
                        this.cards = response.data
                        this.is_loading = false;
                    });
            }
        }
    }
</script>